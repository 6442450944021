import React from 'react'

import { Subheading, Para, Article } from '../../components/article'

export default () => (
  <Article title="Public beta launch" published="February 1st, 2019">
    <Subheading>It is alive!</Subheading>

    <Para>
      We're excited to announce that as of today, <strong>February 1st 2019
      </strong>, <a href="https://whoops.io">Whoops.io</a> is available for
      public beta access &#x1F973;
    </Para>
    <Para>
      We haven't released our final pricing and plans for Whoops yet, but
      we wanted to allow people to sign up and be productive straight
      away.
    </Para>
    <Para>
      So with our public beta, we've also launched 2 pricing plans:
    </Para>
    <ul>
      <li><strong>Free plan</strong> will get you access to our platform,
      and allow you to create up to 20 HTTP or TCP monitors</li>
      <li><strong>Pro plan</strong> will let you create up to a whopping
        500 monitors, and have them checked as often as every 10
        seconds!</li>
    </ul>
    <Para>
      These prices <em>will</em> change at some point in the future, but
      for customers who sign up today, we're aiming to let you keep your
      beta pricing plan for the forseeable future, as a way of saying
      thank you to our early customers.
    </Para>
    <Para>
      So sign up today using the link at the top of this page, or by going
      to <a href="https://app.whoops.io/signup">
      https://app.whoops.io/signup</a>.
    </Para>
  </Article>
)
